import "./css/App.css";
import { useEffect, useState } from "react";
import fetchUsers, { fetchProjects, fetchInquiries, fetchNotes } from "./FetchData";
import PageRoutes from "./Router/PageRoutes";
import { auth } from "./components/Auth/firebase/FirebaseConfig";
import { signOut } from "firebase/auth";

// get loggedIn data from local storage
const getLoggedIn = () => {
  let loggedIn = localStorage.getItem("loggedIn");
  return JSON.parse(loggedIn);
};

// get current user data from local storage
const getCurrentUser = () => {
  let currentUser = localStorage.getItem("currentUser");
  return JSON.parse(currentUser);
};

function App() {
  const [loggedIn, setLoggedIn] = useState(getLoggedIn());
  const [currentUser, setCurrentUser] = useState(getCurrentUser());

  const [projectData, setProjectData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [inquiries, setInquiries] = useState([]);
  const [notes, setNotes] = useState([]);


  useEffect(() => {
    // store users to local storage
    localStorage.setItem("loggedIn", JSON.stringify(loggedIn));

    // store currentUser to local storage
    localStorage.setItem("currentUser", JSON.stringify(currentUser));

    // Fetch data from firebase
    if (loggedIn) {
      fetchUsers(setUsersData);
      fetchProjects(setProjectData);
      fetchInquiries(setInquiries);
      fetchNotes(setNotes)
    }
  }, [loggedIn, currentUser]);

  return (
    <div>
      {loggedIn ? (
        <div className="profile">
          {/* <img src="" alt="" /> */}
          <p>
            {currentUser === "beingvini@gmail.com" ? "beingvin" : currentUser}
            &nbsp; &nbsp; |{" "}
          </p>
          <button
            className="logoutBtn"
            onClick={async () => {
              try {
                await signOut(auth);
                console.log("logged out");
                setLoggedIn(false);
                setCurrentUser("");
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Logout
          </button>
        </div>
      ) : (
        ""
      )}

      <PageRoutes
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        usersData={usersData}
        projectData={projectData}
        inquiries={inquiries}
        notes={notes}

      />
    </div>
  );
}

export default App;
