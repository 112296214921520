import React, { useState } from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import EditUser from "./EditUser";
import AllUsers from "./AllUsers";
import AddUser from "./AddUser";

function ManageUsers({usersData}) {
  const [currentUser, setCurrentUser] = useState("");
  const [switchPage, setswitchPage] = useState("All");


  const dashboard = (switchPage, usersData) => {
    switch (switchPage) {
      case "All":
        return (
          <AllUsers
            setswitchPage={setswitchPage}
            users={usersData}
            setCurrentUser={setCurrentUser}
          />
        );
      case "Edit":
        return (
          <EditUser
            setswitchPage={setswitchPage}
            users={usersData}
            currentUser={currentUser}
          />
        );
      case "Add":
        return <AddUser setswitchPage={setswitchPage} />;
      default:
        return <AllUsers users={usersData} />;
    }
  };

  return (
    <div>
      <div className="container">
        <div className="sectionTitle">
          <h2 onClick={() => window.location.reload()}>Manage Users</h2>
        </div>
        <Link className={style.backBtn} to={"/"}>
          back
        </Link>
        <div className={style.wrapper}>
          <div className={style.sidePenal}>
            <button onClick={() => setswitchPage("All")}>All</button>
            <button onClick={() => setswitchPage("Add")}>Add</button>
          </div>
          <div className={style.dashboard}>
            {/* Display users details  */}
            {dashboard(switchPage, usersData)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageUsers;
