import React from "react";
import style from "./style.module.css";
import db from "../../Auth/firebase/FirebaseConfig";
import { addDoc, serverTimestamp, collection } from "@firebase/firestore";
import bcrypt from "bcryptjs";

// SALT should be created ONE TIME upon sign up
const salt = bcrypt.genSaltSync(5);

// console.log(salt);

function EditUser({ setswitchPage }) {
  const collectionRef = collection(db, process.env.REACT_APP_COLLECTION_NAME_2);

  // Add user to firebase
  const handlAdduser = async (e) => {
    e.preventDefault();

    let Username = e.target.Username.value.toString().toLowerCase();
    let Password = e.target.Password.value;
    let ConfirmPassword = e.target.ConfirmPassword.value;

    if (Password === ConfirmPassword) {
      const hashedPassword = bcrypt.hashSync(Password, salt);

      await addDoc(collectionRef, {
        Username: Username,
        Password: hashedPassword,
        Time: serverTimestamp(),
      });

      setswitchPage("All");
    } else {
      alert("passoword not matching");
    }

    console.log("New user registered");
  };

  return (
    <div className={style.addForm}>
      <form autoComplete="off" onSubmit={handlAdduser}>
        <input
          type="text"
          name="Username"
          placeholder="username"
          className={style.input}
          required
        />
        <input
          type="password"
          name="Password"
          placeholder="password"
          className={style.input}
          required
        />
        <input
          type="text"
          name="ConfirmPassword"
          placeholder="confirm password"
          className={style.input}
          required
        />

        <button className={style.button} type="submit">
          Add
        </button>
      </form>
    </div>
  );
}

export default EditUser;
