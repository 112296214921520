import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoutes({ children, loggedIn }) {
  if (loggedIn) {
    return children;
  }
  return <Navigate to={"/login"} />;
}

export default ProtectedRoutes;
