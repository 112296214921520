import React, { useEffect, useState } from "react";
import db from "../../Auth/firebase/FirebaseConfig";
import { deleteDoc, doc } from "@firebase/firestore";
import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

export default function ProjectOrders({ projectData, currentUser }) {
  const navigate = useNavigate();

  // delete order from firebase
  const handleDelete = (id) => {
    const orderDoc = doc(db, process.env.REACT_APP_COLLECTION_NAME, id);
    console.log("Assignment deleted");
    deleteDoc(orderDoc);
  };

  return (
    <div className="container">
      <div className="sectionTitle">
        <h2 onClick={() => window.location.reload()}>Project Orders</h2>
      </div>
      <Link className={style.backBtn} to={"/"}>
        back
      </Link>
      <div className={style.wrapper}>
        {/* Display Project orders details  */}
        <div className={style.ordersTable}>
          <table className={style.table}>
            <thead>
              <tr>
                <th> Name </th>
                <th>Email </th>
                <th>Phone</th>
                <th>Website</th>
                <th>Type</th>
                <th>Plan</th>

                <th>Remark</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {projectData.map((project) => {
                return (
                  <tr key={project.id} className={style.row}>
                    <td>{project.Name}</td>
                    <td>{project.Email}</td>
                    <td>{project.Phone}</td>
                    <td>{project.Website}</td>
                    <td style={{ textAlign: "center" }}>
                      {project.ProjectType}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {project.ProjectPlan}
                    </td>

                    <td>{project.Remark}</td>

                    <td>
                      <div
                        style={{ textAlign: "center" }}
                        className={style.actionBtns}
                      >
                        &nbsp; &nbsp;
                        {/* <FontAwesomeIcon
                          className={style.icon}
                          icon={faInfoCircle}
                          onClick={() =>
                            navigate("/projects/Orders/details/" + project.id)
                          }
                        
                        /> */}
                        <button
                          className={style.Btn}
                          onClick={() =>
                            navigate("/projects/Orders/details/" + project.id)
                          }
                        >
                          View
                        </button>
                        &nbsp; &nbsp;
                        {/* <FontAwesomeIcon
                          className={style.icon}
                          icon={faPenToSquare}
                          onClick={() =>
                            navigate("/projects/edit/" + project.id)
                          }
                        /> */}
                        <button
                          className={style.Btn}
                          onClick={() =>
                            navigate("/projects/edit/" + project.id)
                          }
                        >
                          Update
                        </button>
                        &nbsp; &nbsp;
                        {currentUser === "beingvini@gmail.com" ? (
                          // <FontAwesomeIcon
                          //   style={{ color: "#fd2155" }}
                          //   className={style.icon}
                          //   icon={faTrash}
                          //   onClick={() => handleDelete(project.id)}
                          // />
                          <button
                            className={style.Btn}
                            style={{ color: "#fd2155" }}
                            onClick={() => handleDelete(project.id)}
                          >
                            Delete
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
