import React from "react";
import style from "./style.module.css";
import { addDoc, collection, serverTimestamp } from "@firebase/firestore";
import db from "../../Auth/firebase/FirebaseConfig";

function AddNote({ encryptData }) {
  // Add Note to firebase
  const addNote = async (e) => {
    e.preventDefault();

    const collectionRef = collection(
      db,
      process.env.REACT_APP_COLLECTION_NAME_4
    );

    let title = e.target.title.value;
    let note = e.target.newNote.value;

    if (note.length) {
      e.target.title.value = "";
      e.target.newNote.value = "";

      await addDoc(collectionRef, {
        Title: encryptData(title),
        Note: encryptData(note),
        Time: serverTimestamp(),
      });
    }
  };
  return (
    <div className={style.notesForm}>
      <form action="#" method="post" onSubmit={addNote}>
        <input
          className={style.input}
          type="text"
          name="title"
          placeholder="Note title"
        />

        <textarea
          className={style.textarea}
          type="text"
          name="newNote"
          placeholder="Type note..."
        />
        <button className={style.button} type="submit">
          New note
        </button>
      </form>
    </div>
  );
}

export default AddNote;
