import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Link, useParams } from "react-router-dom";

function ProjectOrderDetails({ projectData }) {
  const param = useParams();
  const [project, setProject] = useState([]);

  useEffect(() => {
    const currentOrder = projectData.filter((order) => order.id === param.id);
    setProject([...currentOrder]);
  }, [param]);

  return (
    <div className="container">
      <div className="sectionTitle">
        <h2 onClick={() => window.location.reload()}>Project Details</h2>
      </div>
      <Link className={style.backBtn} to={"/Projects/orders"}>
        back
      </Link>
      <div className={style.wrapper}></div>
      {project.length > 0 ? (
        <div className={style.ordersTable + " " + style.orderTable}>
          <table className={style.table}>
            <tbody>
              <tr className={style.row}>
                <th>Client Name </th>
                <td>{project[0].Name}</td>
              </tr>
              <tr className={style.row}>
                <th>Client Email </th>
                <td>{project[0].Email}</td>
              </tr>
              <tr className={style.row}>
                <th>Client Phone</th>
                <td>{project[0].Phone}</td>
              </tr>
              <tr className={style.row}>
                <th>Client Website</th>
                <td>{project[0].Website}</td>
              </tr>
              <tr className={style.row}>
                <th>Project Type</th>
                <td>{project[0].ProjectType}</td>
              </tr>
              <tr className={style.row}>
                <th>Porject Plan</th>
                <td>{project[0].ProjectPlan}</td>
              </tr>
              <tr className={style.row}>
                <th>Project Details</th>
                <td>{project[0].ProjectDetails}</td>
              </tr>
              <tr className={style.row}>
                <th>Project Budget</th>
                <td>{project[0].ProjectBudget}</td>
              </tr>
              <tr className={style.row}>
                <th>Project Timeframe</th>
                <td>{project[0].ProjectTimeframe}</td>
              </tr>
              <tr className={style.row}>
                <th>Additional Details</th>
                <td>{project[0].AdditionalDetails}</td>
              </tr>
              <tr className={style.row}>
                <th>Remark</th>
                <td>{project[0].Remark}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        "No data try again"
      )}
    </div>
  );
}

export default ProjectOrderDetails;
