import React, { useEffect, useRef } from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import db from "../../Auth/firebase/FirebaseConfig";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  updateDoc,
  // serverTimestamp,
} from "@firebase/firestore";

function EditInquiry({ inquiries }) {
  const { id } = useParams();
  const navigate = useNavigate();

  // input Refs
  const idRef = useRef("");
  const nameRef = useRef("");
  const emailRef = useRef("");
  const phoneRef = useRef("");
  const websiteRef = useRef("");
  const messageRef = useRef("");
  const remarkRef = useRef("");

  // update order data from firebase
  const handlUpdate = (e) => {
    e.preventDefault();
    const Id = e.target.Id.value;
    const Name = e.target.Name.value;
    const Email = e.target.Email.value;
    const Website = e.target.Website.value;
    const Phone = e.target.Phone.value;
    const Message = e.target.Message.value;
    const Remark = e.target.Remark.value;

    const userDoc = doc(db, process.env.REACT_APP_COLLECTION_NAME_3, Id);
    updateDoc(userDoc, {
      Name: Name,
      Email: Email,
      Website: Website,
      Phone: Phone,
      Message: Message,
      Remark: Remark,
      // Time: serverTimestamp(),
    });

    console.log("Inquiry updated");
    navigate("/inquiry");
  };

  useEffect(() => {
    const currentInquiry = inquiries.filter((order) => order.id === id);

    idRef.current.value = currentInquiry[0].id;
    nameRef.current.value = currentInquiry[0].Name;
    emailRef.current.value = currentInquiry[0].Email;
    phoneRef.current.value = currentInquiry[0].Phone;
    websiteRef.current.value = currentInquiry[0].Website;
    messageRef.current.value = currentInquiry[0].Message;
    remarkRef.current.value = currentInquiry[0].Remark;
    nameRef.current.focus();
  }, []);

  return (
    <div className="container">
      <div className="sectionTitle">
        <h2 onClick={() => window.location.reload()}>Edit Inquiry</h2>
      </div>
      <Link className={style.backBtn} to={"/inquiry"}>
        back
      </Link>
      <div className={style.wrapper}>
        {" "}
        <div className={style.editForm}>
          <form autoComplete="off" onSubmit={handlUpdate}>
            <input name="Id" type="hidden" ref={idRef} />

            <input
              type="text"
              name="Name"
              placeholder="Name"
              ref={nameRef}
              className={style.input}
              required
            />

            <input
              type="email"
              name="Email"
              ref={emailRef}
              placeholder="Email"
              className={style.input}
              required
            />

            <input
              type="tel"
              name="Phone"
              ref={phoneRef}
              placeholder="Phone   "
              className={style.input}
              required
            />

            <input
              type="url"
              name="Website"
              ref={websiteRef}
              placeholder="Website URL"
              className={style.input}
            />

            <textarea
              cols={30}
              rows={10}
              name="Message"
              ref={messageRef}
              placeholder="Additional Details"
              className={style.textarea}
              required
            />

            <input
              type="text"
              name="Remark"
              ref={remarkRef}
              placeholder="Remark"
              className={style.input}
            />

            <button className={style.button} type="submit">
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditInquiry;
