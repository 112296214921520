import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/Auth/Login";
import Dashboard from "../components/Dashboard/Dashboard";
import ProtectedRoutes from "../Router/ProtectedRoutes";
import ProjectOrders from "../components/Dashboard/manageProjectOrders/ProjectOrders";
import EditProjectOrder from "../components/Dashboard/manageProjectOrders/EditProjectOrder";
import NoPage from "../Router/NoPage";
import ManageUsers from "../components/Dashboard/manageUsers/ManageUsers";
import ProjectOrderDetails from "../components/Dashboard/manageProjectOrders/ProjectOrderDetails";
import Inquiry from "../components/Dashboard/manageInquiry/Inquiry";
import EditInquiry from "../components/Dashboard/manageInquiry/EditInquiry";
import Notes from "../components/Dashboard/manageNotes/Notes";

function PageRoutes({
  currentUser,
  loggedIn,
  usersData,
  projectData,
  setCurrentUser,
  setLoggedIn,
  inquiries,
  notes
}) {
  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            <Dashboard
              projectData={projectData}
              inquiries={inquiries}
              currentUser={currentUser}
            />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/login"
        element={
          loggedIn ? (
            <Navigate to={"/"} />
          ) : (
            <Login
              users={usersData}
              setLoggedIn={setLoggedIn}
              setCurrentUser={setCurrentUser}
            />
          )
        }
      />

      <Route
        path="/projects/orders"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            <ProjectOrders
              projectData={projectData}
              currentUser={currentUser}
            />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/projects/Orders/details/:id"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            <ProjectOrderDetails projectData={projectData} />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/projects/edit/:id"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            <EditProjectOrder projectData={projectData} />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/inquiry"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            <Inquiry inquiries={inquiries} currentUser={currentUser} />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/Notes"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            <Notes notes={notes} currentUser={currentUser} />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/inquiry/edit/:id"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            <EditInquiry inquiries={inquiries} />
          </ProtectedRoutes>
        }
      />

      <Route
        path="/users"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            {currentUser === "beingvini@gmail.com" ? (
              <ManageUsers usersData={usersData} />
            ) : (
              <NoPage />
            )}
          </ProtectedRoutes>
        }
      />

      <Route
        path="*"
        element={
          <ProtectedRoutes loggedIn={loggedIn}>
            <NoPage />
          </ProtectedRoutes>
        }
      />
    </Routes>
  );
}

export default PageRoutes;
