import { query, orderBy, onSnapshot, collection } from "@firebase/firestore";
import db from "./components/Auth/firebase/FirebaseConfig";

// get users data from firebase
function fetchUsers(setUsersData) {
  const collectionRef2 = collection(
    db,
    process.env.REACT_APP_COLLECTION_NAME_2
  );
  // console.log("fetching users...");

  const unsubscribe = onSnapshot(
    query(collectionRef2, orderBy("Time", "desc")),
    (snapshot) => {
      const usersDB = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUsersData(usersDB);
    }
  );
  // Unsubscribe the listener when the component unmounts
  return () => unsubscribe();
}

// get projects data from firebase
function fetchProjects(setProjectData) {
  // console.log("Fetching Projects");
  const collectionRef = collection(db, process.env.REACT_APP_COLLECTION_NAME);

  const unsubscribe = onSnapshot(
    query(collectionRef, orderBy("Time", "desc")),
    (snapshot) => {
      const projectsDB = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProjectData(projectsDB);
    }
  );

  // Unsubscribe the listener when the component unmounts
  return () => unsubscribe();
}

// get inquiry data from firebase
function fetchInquiries(setInquiries) {
  // console.log("Fetching Projects");
  const collectionRef = collection(db, process.env.REACT_APP_COLLECTION_NAME_3);

  const unsubscribe = onSnapshot(
    query(collectionRef, orderBy("Time", "desc")),
    (snapshot) => {
      const inquiryDB = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setInquiries(inquiryDB);
    }
  );

  // Unsubscribe the listener when the component unmounts
  return () => unsubscribe();
}

// get todos data from firebase
async function fetchNotes(setNotes) {
  const collectionRef = collection(db, process.env.REACT_APP_COLLECTION_NAME_4);

  try {
    const unsubscribe = onSnapshot(
      query(collectionRef, orderBy("Time", "desc")),
      async (snapshot) => {
        const data = await snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setNotes(data);
      }
    );
  } catch (error) {
    console.error("Error fetching note:", error);
  }
}

export default fetchUsers;
export { fetchProjects };
export { fetchInquiries };
export { fetchNotes };
