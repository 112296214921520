import React, { useEffect, useState } from "react";
import db from "../../Auth/firebase/FirebaseConfig";
import { deleteDoc, doc } from "@firebase/firestore";
import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

function Inquiry({ inquiries, currentUser }) {
  // console.log(inquiries);
  const navigate = useNavigate();

  // delete order from firebase
  const handleDelete = (id) => {
    const orderDoc = doc(db, process.env.REACT_APP_COLLECTION_NAME_3, id);
    console.log("Assignment deleted");
    deleteDoc(orderDoc);
  };

  return (
    <div className="container">
      <div className="sectionTitle">
        <h2 onClick={() => window.location.reload()}>Project Inquiries</h2>
      </div>
      <Link className={style.backBtn} to={"/"}>
        back
      </Link>
      <div className={style.wrapper}>
        {/* Display Project orders details  */}
        <div className={style.inquiryTable}>
          <table className={style.table}>
            <thead>
              <tr>
                <th> Name </th>
                <th>Email </th>
                <th>Phone</th>
                <th>Website</th>
                <th>Message</th>
                <th>Remark</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {inquiries.map((inquiry) => {
                return (
                  <tr key={inquiry.id} className={style.row}>
                    <td>{inquiry.Name}</td>
                    <td>{inquiry.Email}</td>
                    <td>{inquiry.Phone}</td>
                    <td>{inquiry.Website}</td>
                    <td>{inquiry.Message}</td>
                    <td>{inquiry.Remark}</td>

                    <td>
                      <div
                        style={{ textAlign: "center" }}
                        className={style.actionBtns}
                      >
                        &nbsp; &nbsp;
                        {/* <FontAwesomeIcon
                      className={style.icon}
                      icon={faPenToSquare}
                      onClick={() =>
                        navigate("/projects/edit/" + project.id)
                      }
                    /> */}
                        <button
                          className={style.Btn}
                          onClick={() =>
                            navigate("/inquiry/edit/" + inquiry.id)
                          }
                        >
                          Update
                        </button>
                        &nbsp; &nbsp;
                        {currentUser === "beingvini@gmail.com"? (
                          // <FontAwesomeIcon
                          //   style={{ color: "#fd2155" }}
                          //   className={style.icon}
                          //   icon={faTrash}
                          //   onClick={() => handleDelete(project.id)}
                          // />
                          <button
                            className={style.Btn}
                            style={{ color: "#fd2155" }}
                            onClick={() => handleDelete(inquiry.id)}
                          >
                            Delete
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Inquiry;
