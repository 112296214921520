import React, { useEffect, useState } from "react";
import db from "../../Auth/firebase/FirebaseConfig";
import { deleteDoc, doc } from "@firebase/firestore";
import style from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import AddNote from "./AddNote";
import ViewNote from "./ViewNote";
import { AnimatePresence } from "framer-motion";
import { AES, enc } from "crypto-js";

const secretKey = process.env.REACT_APP_NOTES_SECRET_KEY;

function Notes({ notes, currentUser }) {
  // console.log(notes);
  const navigate = useNavigate();

  const [noteId, setNoteId] = useState(false);
  const [view, setView] = useState(false);

  // handle view
  const handleView = (id) => {
    setNoteId(id);
    setView(true);
  };
  // delete order from firebase
  const handleDelete = (id) => {
    const orderDoc = doc(db, process.env.REACT_APP_COLLECTION_NAME_4, id);
    deleteDoc(orderDoc);
  };

  // encrypt data function
  const encryptData = (str) => {
    try {
      const encryptedData = AES.encrypt(str, secretKey).toString();
      return encryptedData;
    } catch (error) {
      console.error("Error in encrypting note:", error);
    }
  };

  // decrypt data function
  const decryptData = (str) => {
    try {
      var bytes = AES.decrypt(str, secretKey);
      var decryptedData = bytes.toString(enc.Utf8);
      return decryptedData;
    } catch (error) {
      console.error("Error in decrypting note:", error);
    }
  };

  // function to convert timestamp to datetime
  const dateTime = (seconds) => {
    if (seconds !== null) {
      let datetime = new Date(seconds * 1000);
      return `${datetime.getFullYear()}/${datetime.getMonth()}/${datetime.getDay()} \xa0 ${datetime.getHours()}:${datetime.getMinutes()}:${datetime.getSeconds()} `;
    }
    return "";
  };

  return (
    <div className="container">
      <div className="sectionTitle">
        <h2 onClick={() => window.location.reload()}>Notes</h2>
      </div>
      <Link className={style.backBtn} to={"/"}>
        back
      </Link>
      <div className={style.wrapper}>
        <AddNote encryptData={encryptData} />
        {/* Display Notes  */}
        <div className={style.notesTable}>
          <table className={style.table}>
            <thead>
              <tr>
                <th className={style.thTitle}> Title </th>
                <th className={style.thNote}>Note</th>
                <th className={style.thDateTime}>Date Time</th>
                <th className={style.thAction}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {notes.map((Notes) => {
                return (
                  <tr key={Notes.id} className={style.row}>
                    <td>{decryptData(Notes.Title)}</td>
                    <td>
                      <button
                        className={style.noteBtn}
                        onClick={() => handleView(Notes.id)}
                      >
                        {decryptData(Notes.Note).length > 50
                          ? decryptData(Notes.Note).substring(0, 47) + " ..."
                          : decryptData(Notes.Note)}
                      </button>
                    </td>
                    <td>
                      {Notes.Time === null
                        ? "wait... "
                        : dateTime(Notes.Time.seconds)}
                    </td>

                    <td>
                      <div
                        style={{ textAlign: "center" }}
                        className={style.actionBtns}
                      >
                        &nbsp; &nbsp;
                        {currentUser === "beingvini@gmail.com" ? (
                          // <FontAwesomeIcon
                          //   style={{ color: "#fd2155" }}
                          //   className={style.icon}
                          //   icon={faTrash}
                          //   onClick={() => handleDelete(project.id)}
                          // />
                          <button
                            className={style.Btn}
                            style={{ color: "#fd2155" }}
                            onClick={() => handleDelete(Notes.id)}
                          >
                            Delete
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <AnimatePresence>
        {view ? (
          <ViewNote
            noteId={noteId}
            notes={notes}
            setView={setView}
            encryptData={encryptData}
            decryptData={decryptData}
          />
        ) : (
          ""
        )}
      </AnimatePresence>
    </div>
  );
}

export default Notes;
