import React from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";

function Dashboard({ projectData, inquiries, currentUser }) {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2 onClick={() => window.location.reload()}>Dashboard</h2>
      </div>
      <div></div>
      <div className={style.wrapper}>
        <div className={style.sidePenal}>
          <Link to={"/projects/orders"}>assignments</Link>
          <Link to={"/inquiry"}>Inquiry</Link>
          <Link to={"/notes"}>Notes</Link>
          {currentUser === "beingvini@gmail.com" ? (
            <Link to={"/Users"}>Manage Users</Link>
          ) : (
            " "
          )}
        </div>
        <div className={style.dashboard}>
          <h2>Statastics</h2>
          <div className={style.statastics}>
            <div>
              <h4> Total orders</h4>
              <h1>{projectData.length}</h1>
            </div>
            <div>
              <h4>Total Inquiries</h4>
              <h1>{inquiries.length}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
