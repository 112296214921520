import React, { useState } from "react";
import style from "./style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteDoc, doc } from "@firebase/firestore";
import db from "../../Auth/firebase/FirebaseConfig";

function AllUsers({ users, setswitchPage, setCurrentUser }) {
  let slno = 1;

  // delete user from firebase
  const handleDelete = (id) => {
    const orderDoc = doc(db, process.env.REACT_APP_COLLECTION_NAME_2, id);
    deleteDoc(orderDoc);
  };

  return (
    <table className={style.table}>
      <thead>
        <tr>
          <th> S/No </th>
          <th> Username </th>
          <th>Password</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <tr key={user.id} className={style.row}>
              <td style={{ width: "10%", textAlign: "center" }}>{slno++}</td>
              <td style={{ width: "30%", textAlign: "center" }}>
                {user.Username}
              </td>
              <td>{user.Password}</td>
              <td style={{ width: "10%", textAlign: "center" }}>
                <div
                  style={{ textAlign: "center" }}
                  className={style.actionBtns}
                >
                  &nbsp; &nbsp;
                  <FontAwesomeIcon
                    className={style.icon}
                    icon={faPenToSquare}
                    onClick={() => {
                      setCurrentUser(user.id);
                      setswitchPage("Edit");
                    }}
                  />
                  &nbsp; &nbsp;
                  <FontAwesomeIcon
                    className={style.icon}
                    icon={faTrash}
                    onClick={() => handleDelete(user.id)}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default AllUsers;
