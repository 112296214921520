import React, { useState } from "react";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase/FirebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

function Login({ setLoggedIn, setCurrentUser }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // cloud login system
  const handleSubmit = (e) => {
    e.preventDefault();
    const username = e.target.username.value.toString().toLowerCase();
    const password = e.target.password.value;

    const clearValues = () => {
      setLoading(true);
      e.target.username.value = "";
      e.target.password.value = "";
      e.target.username.focus();
    };

    // firebase login
    const signIn = async () => {
      try {
        await signInWithEmailAndPassword(auth, username, password);

        setLoggedIn(true);
        setCurrentUser(auth?.currentUser?.email);
        console.log("User Logged in ");
        setLoading(false);
        return navigate("/");
      } catch (err) {
        setLoading(false);

        if (err.message === "Firebase: Error (auth/user-not-found).")
          alert("user-not-found");
        else if (err.message === "Firebase: Error (auth/invalid-email).")
          alert("invalid-email");
        else if (err.message === "Firebase: Error (auth/wrong-password).")
          alert("wrong-password");
      }
    };

    signIn();
    clearValues();
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        alert("It's taking longer than expected, Try again later !");
      }, 50000);
    }
  };

  return (
    <div className="container">
      <div className="sectionTitle">
        <h2 onClick={() => window.location.reload()}> User login</h2>
      </div>

      <div className={style.wrapper}>
        <form
          className={style.loginForm}
          action=""
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <fieldset className={style.fieldset}>
            <legend className={style.legend}>Login</legend>
            <div>
              <label htmlFor="username" className={style.label}>
                Username
              </label>
              <input
                type="text"
                name="username"
                placeholder="Username"
                className={style.input}
                required
              />
            </div>
            <div>
              <label htmlFor="password" className={style.label}>
                Password
              </label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                className={style.input}
                required
              />
            </div>
            <div>
              <button type="submit" className={style.button}>
                Login
              </button>
              <button type="Reset" className={style.button}>
                Reset
              </button>
            </div>

            {loading ? <div className={style.loading}></div> : ""}
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default Login;
