import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import db from "../../Auth/firebase/FirebaseConfig";
import { doc, updateDoc, serverTimestamp } from "@firebase/firestore";
import { motion } from "framer-motion";

function ViewNote({ noteId, setView, notes, encryptData, decryptData }) {
  const [title, setTitle] = useState("");
  const [noteBody, setNoteBody] = useState("");
  const [hasChanges, setHasChanges] = useState(false); // Track changes

  // filter current note from notes data
  const note = notes.filter((note) => note.id === noteId);

  // new line "/n" read in tags
  // const escapedNewLineToLineBreakTag = (string) => {
  //   return string.split("\n").map((item, index) => {
  //     return index === 0 ? item : [item, <br key={index} />];
  //   });
  // };

  //Update note and close model
  const handleClose = async (e) => {
    e.preventDefault();
    if (e.target.id === "modelContainer" || e.target.id === "closeBtn") {
      setView(false);
    }
  };

  // Handle changes in input fields
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setHasChanges(true);
  };

  const handleNoteBodyChange = (e) => {
    setNoteBody(e.target.value);
    setHasChanges(true);
  };

  // set note title and note body to states
  useEffect(() => {
    const decryptedTitle = decryptData(note[0].Title);
    const decryptedNote = decryptData(note[0].Note);
    setTitle(decryptedTitle);
    setNoteBody(decryptedNote);
    // eslint-disable-next-line
  }, [decryptData]);

  // Update note and close model when title or noteBody changes
  // eslint-disable-next-line
  useEffect(() => {
    const userDoc = doc(db, process.env.REACT_APP_COLLECTION_NAME_4, noteId);

    const updateNote = async () => {
      try {
        await updateDoc(userDoc, {
          Title: encryptData(title),
          Note: encryptData(noteBody),
          Time: serverTimestamp(),
        });
      } catch (error) {
        console.error("Error updating note:", error);
      }
    };

    if (hasChanges) {
      // Only update if there are changes
      updateNote();
    }
    // eslint-disable-next-line
  }, [title, noteBody]);

  return (
    <motion.div
      className={style.modelContainer}
      id="modelContainer"
      onClick={handleClose}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className={style.model}
        initial={{
          opacity: 0,
          scale: 0.8,
        }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0, scale: 0.8 }}
      >
        {/*<div className={style.noteTitle}>
     <h3>{note[0].Title}</h3>
   </div>
   <div className={style.noteBody}>
     <p>{escapedNewLineToLineBreakTag(note[0].Note)}</p>
</div>*/}
        <form className={style.form}>
          <input
            className={style.noteTitle}
            type="text"
            name="title"
            placeholder="Note title"
            value={title}
            onChange={handleTitleChange}
          />
          <br />
          <textarea
            className={style.noteBody}
            type="text"
            name="newNote"
            placeholder="Type note..."
            value={noteBody}
            onChange={handleNoteBodyChange}
          />
          <button className={style.close} id="closeBtn" onClick={handleClose}>
            Close
          </button>
        </form>
      </motion.div>
    </motion.div>
  );
}

export default ViewNote;
