import React, { useEffect, useRef } from "react";
import style from "./style.module.css";
import db from "../../Auth/firebase/FirebaseConfig";
import { doc, updateDoc } from "@firebase/firestore";
import bcrypt from "bcryptjs";

// SALT should be created ONE TIME upon sign up
const salt = bcrypt.genSaltSync(5);

function EditUser({ users, setswitchPage, currentUser: id }) {
  // input Refs
  const idRef = useRef("");
  const usernameRef = useRef("");
  const passwordRef = useRef("");

  // update user from firebase
  const handlUpdate = (e) => {
    e.preventDefault();
    const Id = e.target.Id.value;
    const Username = e.target.Username.value.toString().toLowerCase();
    const Password = e.target.Password.value;

    const currentUser = users.filter((user) => user.id === Id);
    const userDoc = doc(db, process.env.REACT_APP_COLLECTION_NAME_2, Id);

    if (currentUser[0].Username !== Username) {
      console.log("Username changed");
      updateDoc(userDoc, {
        Username: Username,
      });
    } else if (currentUser[0].Password !== Password) {
      console.log("Password changed");
      const hashedPassword = bcrypt.hashSync(Password, salt);
      updateDoc(userDoc, {
        Password: hashedPassword,
      });
    }

    console.log("User updated");
    setswitchPage("All");
  };

  useEffect(() => {
    const currentUser = users.filter((user) => user.id === id);
    idRef.current.value = currentUser[0].id;
    usernameRef.current.value = currentUser[0].Username;
    passwordRef.current.value = currentUser[0].Password;
    usernameRef.current.focus();
  }, []);

  return (
    <div className={style.editForm}>
      <form autoComplete="off" onSubmit={handlUpdate}>
        <input name="Id" type="hidden" ref={idRef} />
        <input
          type="text"
          name="Username"
          ref={usernameRef}
          placeholder="edit username"
          className={style.input}
          required
        />
        <input
          type="password"
          name="Password"
          ref={passwordRef}
          placeholder="edit password"
          className={style.input}
          required
        />

        <button className={style.button} type="submit">
          Update
        </button>
      </form>
    </div>
  );
}

export default EditUser;
