import React, { useEffect, useRef } from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import db from "../../Auth/firebase/FirebaseConfig";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  updateDoc,
  // serverTimestamp,
} from "@firebase/firestore";

function EditProjectOrder({ projectData }) {
  const { id } = useParams();
  const navigate = useNavigate();

  // input Refs
  const idRef = useRef("");
  const nameRef = useRef("");
  const emailRef = useRef("");
  const phoneRef = useRef("");
  const websiteRef = useRef("");
  const typeRef = useRef("");
  const planRef = useRef("");
  const budgetRef = useRef("");
  const detailsRef = useRef("");
  const timeframeRef = useRef("");
  const additionalDetailsRef = useRef("");
  const remarkRef = useRef("");

  // update order data from firebase
  const handlUpdate = (e) => {
    e.preventDefault();
    const Id = e.target.Id.value;
    const Name = e.target.Name.value;
    const Email = e.target.Email.value;
    const Website = e.target.Website.value;
    const Phone = e.target.Phone.value;
    const AdditionalDetails = e.target.AdditionalDetails.value;
    const ProjectType = e.target.ProjectType.value;
    const ProjectPlan = e.target.ProjectPlan.value;
    const ProjectDetails = e.target.ProjectDetails.value;
    const ProjectBudget = e.target.ProjectBudget.value;
    const ProjectTimeframe = e.target.ProjectTimeframe.value;
    const Remark = e.target.Remark.value;

    const userDoc = doc(db, process.env.REACT_APP_COLLECTION_NAME, Id);
    updateDoc(userDoc, {
      Name: Name,
      Email: Email,
      Website: Website,
      Phone: Phone,
      ProjectType: ProjectType,
      ProjectPlan: ProjectPlan,
      ProjectBudget: ProjectBudget,
      ProjectDetails: ProjectDetails,
      ProjectTimeframe: ProjectTimeframe,
      AdditionalDetails: AdditionalDetails,
      Remark: Remark,
      // Time: serverTimestamp(),
    });

    console.log("Assignment updated");
    navigate("/projects/orders");
  };

  useEffect(() => {
    const currentOrder = projectData.filter((order) => order.id === id);

    idRef.current.value = currentOrder[0].id;
    nameRef.current.value = currentOrder[0].Name;
    emailRef.current.value = currentOrder[0].Email;
    phoneRef.current.value = currentOrder[0].Phone;
    websiteRef.current.value = currentOrder[0].Website;
    typeRef.current.value = currentOrder[0].ProjectType;
    planRef.current.value = currentOrder[0].ProjectPlan;
    budgetRef.current.value = currentOrder[0].ProjectBudget;
    detailsRef.current.value = currentOrder[0].ProjectDetails;
    timeframeRef.current.value = currentOrder[0].ProjectTimeframe;
    additionalDetailsRef.current.value = currentOrder[0].AdditionalDetails;
    remarkRef.current.value = currentOrder[0].Remark;
    nameRef.current.focus();
  }, []);

  return (
    <div className="container">
      <div className="sectionTitle">
        <h2 onClick={() => window.location.reload()}>Edit Project Orders</h2>
      </div>
      <Link className={style.backBtn} to={"/Projects/orders"}>
        back
      </Link>
      <div className={style.wrapper}>
        {" "}
        <div className={style.editForm}>
          <form autoComplete="off" onSubmit={handlUpdate}>
            <input name="Id" type="hidden" ref={idRef} />

            <div>
              <input
                type="text"
                name="Name"
                placeholder="Name"
                ref={nameRef}
                className={style.input}
                required
              />

              <input
                type="email"
                name="Email"
                ref={emailRef}
                placeholder="Email"
                className={style.input}
                required
              />

              <input
                type="tel"
                name="Phone"
                ref={phoneRef}
                placeholder="Phone   "
                className={style.input}
                required
              />

              <input
                type="url"
                name="Website"
                ref={websiteRef}
                placeholder="Website URL"
                className={style.input}
              />

              <textarea
                cols={30}
                rows={10}
                name="AdditionalDetails"
                ref={additionalDetailsRef}
                placeholder="Additional Details"
                className={style.textarea}
                required
              />

              <input
                type="text"
                name="Remark"
                ref={remarkRef}
                placeholder="Remark"
                className={style.input}
              />
            </div>

            <div>
              <select
                name="ProjectType"
                ref={typeRef}
                defaultValue={"DEFAULT"}
                className={style.select}
                required
              >
                <option value="DEFAULT" disabled>
                  Select Type
                </option>
                <option value="Single">Single</option>
                <option value="Multipage">Multipage</option>
                <option value="Landing Page">Landing Page</option>
                <option value="Website Design">Website Design</option>
              </select>

              <select
                name="ProjectPlan"
                ref={planRef}
                defaultValue={"DEFAULT"}
                className={style.select}
                required
              >
                <option value="DEFAULT" disabled>
                  Select Plan
                </option>
                <option value="Basic">Basic</option>
                <option value="Standard">Standard</option>
                <option value="Premium">Premium</option>
              </select>

              <textarea
                type="text"
                name="ProjectDetails"
                cols={30}
                rows={2}
                ref={detailsRef}
                placeholder="Project Details"
                className={style.textarea}
                required
              />

              <select
                name="ProjectBudget"
                ref={budgetRef}
                defaultValue={"DEFAULT"}
                className={style.select}
                required
              >
                <option value="DEFAULT" disabled>
                  Select Budget
                </option>
                <option value="> 5K"> {"> 5K"}</option>
                <option value="5K - 10K">5K - 10K</option>
                <option value="10K - 15K">10K - 15K</option>
                <option value="+ 20K">+ 20K</option>
              </select>

              <select
                name="ProjectTimeframe"
                ref={timeframeRef}
                defaultValue={"DEFAULT"}
                className={style.select}
                required
              >
                <option value="DEFAULT" disabled>
                  Select Timeframe
                </option>
                <option value="Standard">Standard</option>
                <option value="WITHIN 1 MONTH">WITHIN 1 MONTH</option>
                <option value="WITHIN 2 MONTH">WITHIN 2 MONTH</option>
                <option value="WITHIN 4 MONTH">WITHIN 4 MONTH</option>
              </select>

              <button className={style.button} type="submit">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditProjectOrder;
